import { matchPath, useLocation } from 'react-router-dom'

import {
  TrackCourseDashboardContentClickedCclCourseBlockPartsFragment,
  TrackCourseDashboardContentClickedCourseBlockPartsFragment,
  TrackCourseDashboardContentClickedCoursePartsFragment,
  TrackCourseDashboardContentClickedCourseSessionPartsFragment,
  useTrackServerEventMutation
} from 'gql'

import { CourseIdentifiersType } from 'utils/courseUtils'

import { LESSON_VIEWER_URL } from './url'
import { getAnonymousId } from './tracking/segment'

export const CONTENT_MODE_ON_DEMAND = 'on-demand'
export const CONTENT_MODE_ASYNC = 'async'
export const CONTENT_MODE_PREVIEW = 'preview'
export const CONTENT_MODE_PRE_SESSION = 'pre-session'
export const CONTENT_MODE_IN_SESSION = 'in-session'
export const CONTENT_MODE_POST_SESSION = 'post-session'

/* eslint-disable camelcase */
export interface CourseRelatedIdentifierProperties {
  course_id?: string | number
  ccl_course_id?: string | number
  ccl_local_id?: string | number | null
  content_mode: string | null
  course_session_id?: string | number | null
  ccl_course_session_id?: string | number | null
  ccl_course_static_id?: string | number | null
  course_title?: string
}
/* eslint-enable camelcase */

const COURSES_PATH = '/courses/'

export const useContentMode = (courseIdentifiers?: CourseIdentifiersType) => {
  const { pathname } = useLocation()

  const contentMode = contentModeFromPath(pathname)

  if (contentMode) {
    return contentMode
  }

  const courseSession = courseIdentifiers?.courseSession

  if (!courseSession) {
    return null
  }

  return contentModeFromCourseSession(courseSession)
}

export const contentModeFromCourseSession = (
  courseSession:
    | {
        hasEnded: boolean
        hasStarted: boolean
      }
    | null
    | undefined
) => {
  if (!courseSession) {
    return null
  }

  if (courseSession.hasEnded) {
    return CONTENT_MODE_POST_SESSION
  }

  if (courseSession.hasStarted) {
    return CONTENT_MODE_IN_SESSION
  }

  return CONTENT_MODE_PRE_SESSION
}

const contentModeFromPath = (pathname: string) => {
  if (!pathname.startsWith('/courses')) {
    return CONTENT_MODE_ASYNC
  }

  if (pathname.includes('/preview')) {
    return CONTENT_MODE_PREVIEW
  }

  if (pathname.includes('/on-demand')) {
    return CONTENT_MODE_ON_DEMAND
  }
}

export const isOnCoursePage = (pathname: string) => {
  const coursePage =
    pathname.startsWith(COURSES_PATH) &&
    pathname.split('/')[2] !== undefined &&
    !pathname.includes('/guides/') &&
    !pathname.includes('/events/') &&
    !pathname.includes('/artifacts/')
  const ignoredPaths = ['/courses/active']

  return coursePage && !ignoredPaths.includes(pathname)
}

export const isOnLessonPage = (pathname: string) => {
  return !!matchPath(pathname, { path: LESSON_VIEWER_URL })
}

export const isOnProgramPage = (pathname: string) => {
  const programPathParts = pathname.split('/programs/')
  return programPathParts[1] !== undefined && programPathParts[1] !== ''
}

export const isOnGuidePage = (pathname: string) => {
  const guidePathParts = pathname.split('/guides/')
  return guidePathParts[1] !== undefined && guidePathParts[1] !== ''
}

export const isOnArtifactPage = (pathname: string) => {
  const artifactPathParts = pathname.split('/artifacts/')
  return artifactPathParts[1] !== undefined && artifactPathParts[1] !== ''
}

type ContentType = 'artifact' | 'guide' | 'course' | 'lesson' | 'program' | undefined
export const contentTypeFromPathname: (pathname: string) => ContentType = (pathname) => {
  if (isOnProgramPage(pathname)) return 'program'
  if (isOnLessonPage(pathname)) return 'lesson'
  if (isOnCoursePage(pathname)) return 'course'
  if (isOnArtifactPage(pathname)) return 'artifact'
  if (isOnGuidePage(pathname)) return 'guide'
}

export type CoursePageUrlParts = {
  courseSlug: string
  courseSessionId?: string | null
  contentMode?: string | null
}

export const parseCoursePageUrl = (pathname: string): CoursePageUrlParts | undefined => {
  if (isOnCoursePage(pathname)) {
    const parts = pathname.substring(COURSES_PATH.length).split('/')
    const courseSlug = parts[0]
    const courseSessionId = parts[2]

    return {
      courseSlug,
      courseSessionId,
      contentMode: contentModeFromPath(pathname)
    }
  }
}

export const useTrackCourseDashboardContentClicked = () => {
  const [trackServerEvent] = useTrackServerEventMutation()
  const { pathname } = useLocation()

  return ({
    contentMode,
    contentType,
    contentTitle,
    contentSanityId,
    contentCclLocalId,
    contentIndex = 0,
    course,
    courseSession,
    courseBlock,
    courseBlockIndex = 0,
    courseRelatedIdentifiers,
    destination
  }: {
    courseRelatedIdentifiers?: CourseRelatedIdentifierProperties
    contentMode: string
    contentType: string
    contentTitle: string
    contentSanityId?: string
    contentCclLocalId?: string | number
    contentIndex?: number
    course?: TrackCourseDashboardContentClickedCoursePartsFragment
    courseSession?: TrackCourseDashboardContentClickedCourseSessionPartsFragment | null
    courseBlock:
      | TrackCourseDashboardContentClickedCourseBlockPartsFragment
      | TrackCourseDashboardContentClickedCclCourseBlockPartsFragment
    courseBlockIndex?: number
    destination: string
  }) => {
    const relatedIdentifiersForCourse = courseRelatedIdentifiers || {
      course_id: course?.id,
      course_title: course?.title,
      course_session_id: courseSession?.id,
      sanity_course_id: course?.sanityId
    }

    trackServerEvent({
      variables: {
        input: {
          event: 'Content Clicked - Server',
          anonymousId: getAnonymousId(),
          properties: {
            content_type: contentType,
            content_title: contentTitle,
            content_sanity_id: contentSanityId,
            display_type: 'thumbnail',
            location: 'course_dashboard',
            content_ccl_local_id: contentCclLocalId,
            referrer: pathname,
            destination,
            relatedIdentifiers: {
              ...relatedIdentifiersForCourse,
              course_block_id: courseBlock.id,
              course_block_title: courseBlock.title,
              course_block_number: courseBlockIndex + 1,
              sanity_content_index: contentIndex + 1,
              content_mode: contentMode
            }
          }
        }
      }
    })
  }
}
